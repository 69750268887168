export class UrlSearch extends HTMLElement {
    constructor() {
        super();
        // ShadowDOM
        const shadow = this.attachShadow({ mode: 'open' });
        // Container
        const wrapper = document.createElement('div');
        wrapper.id = 'url-search';

        const innerWrapper = document.createElement('div');
        innerWrapper.classList.add('search');
        // innerWrapper.innerHTML = `
        // <svg id="url-search-icon" class="bi" width="24" height="24">
        //     <use xlink:href="https://new.urlinsgroup.com/images/svg/bootstrap-icons.svg#search"></use>
        // </svg>
        // `;
        innerWrapper.innerHTML = `
        <svg id="url-search-icon" class="bi" width="24" height="24">
            <use xlink:href="/node_modules/bootstrap-icons/bootstrap-icons.svg#search"></use>
        </svg>
        `;

        const input = document.createElement('input');
        input.type = 'text';
        input.name = 'q';
        input.id = 'search-input';
        input.classList.add('url-search-input');
        input.setAttribute('autofocus', 'autofocus');

        innerWrapper.append(input);

        const btn = document.createElement('button');
        btn.type = 'button';
        btn.classList.add('close');
        btn.classList.add('url-search-close');
        btn.ariaLabel = 'Close';
        btn.innerHTML = `<span aria-hidden="true">&times;</span>`;

        innerWrapper.append(btn);
        wrapper.appendChild(innerWrapper);

        const style = document.createElement('style');
        style.textContent = `
            .search {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            #url-search-icon {
                position: absolute;
                z-index: 99;
                color: #46A2D0;
                right: -5px;
                cursor: pointer;
                fill: currentColor;
            }

            .url-search-close {
                position: absolute;
                display: none;
                color: #46A2D0;
                border: none;
                background: unset;
            }

            #search-input {
                border: none;
                border-radius: 50%;
                background-color: rgb(11, 89, 129);
                font-family: inherit;
                font-size: 1rem;
                color: #46A2D0;
                outline: none;
                width: 0;
                padding: 0;
                transition: 400ms width ease-in-out,
                    400ms padding ease-in-out,
                    400ms border-radius ease-in-out,
                    400ms background-color ease-in-out;
            }

            #url-search.url-search-open input#search-input {
                width: 25rem;
                padding: 1rem 2.5rem;
                border-radius: .2rem;
                background-color: #DCF2FE;
            }

            #url-search.url-search-open #url-search-icon {
                position: relative;
                left: 2rem;
            }

            #url-search.url-search-open .url-search-close {
                right: 8px;
                display: block;
            }
        `;

        shadow.appendChild(style);
        shadow.appendChild(wrapper);

        this.search = this.shadowRoot.querySelector('#url-search');
        this.searchIcon = this.shadowRoot.querySelector('#url-search-icon');
        this.searchInput = this.shadowRoot.querySelector('#search-input');
        this.searchClose = this.shadowRoot.querySelector('.url-search-close');

        this.searchIcon.addEventListener('click', (evt) => {
          evt.preventDefault();
          this.search.classList.add('url-search-open');
          this.searchInput.focus();
        });

        this.searchClose.addEventListener('click', () => {
          this.searchInput.value = '';
          this.search.classList.remove('url-search-open');
        });

        this.searchInput.addEventListener('keyup', (evt) => {
          if (evt.keyCode === 13) {
            window.location.href = `https://new.urlinsgroup.com/product-search.html?q=${this.searchInput.value}`;
          }
        });
    }
}

customElements.define('url-search', UrlSearch);
